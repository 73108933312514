import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFeedYoutubeIndex = lazy(
  () => import("./feed-youtube-index-DSxGQNdV.js").then((module) => ({
    default: module.FeedYoutubeIndex
  }))
);
function FeedYoutubeIndex({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFeedYoutubeIndex, { ...props });
}
const useFeedYoutubeIndexContent = () => {
  return useMemo(
    () => ({
      feedYoutubeIndex: {
        component: FeedYoutubeIndex
      }
    }),
    []
  );
};
export {
  LazyFeedYoutubeIndex as FeedYoutubeIndex,
  useFeedYoutubeIndexContent
};
